import React from 'react'
import './Spinner.css'

function Spinner() {
    return (
        <div className="loader">
            Loading...
        </div>
    )
}

export default Spinner